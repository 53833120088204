import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  events: 'Eventos',
  exhibitors: 'Expositores',
  exhibitor: 'Expositor',
  edit: 'Editar',
  add_spacer: 'Adicionar separador',
  add_stage: 'Adicionar Stage',
  stages_message: 'Stages/Palcos disponibilizados nos formulários de sessões',
  edit_checkin: 'Editar check-in',
  create: 'Criar',
  delete: 'Apagar',
  remove: 'Remover',
  name: 'Nome',
  first_last_name: 'Primeiro e último nome',
  name_placeholder: 'Digite o seu nome',
  email: 'Email',
  invalid_email: 'Este e-mail é inválido',
  email_already_associated: 'O email introduzido já se encontra associado a outra inscrição',
  description: 'Descrição',
  category: 'Categoria',
  categories: 'Categorias',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descarregar',
  upload: 'Upload',
  change: 'Mudar',
  apply: 'Guardar',
  empty_field_not_allowed: 'Este campo não pode estar vazio',
  next: 'Seguinte',
  previous: 'Anterior',
  close: 'Fechar',
  cancel: 'Cancelar',
  items: 'Itens',
  confirm: 'Confirmar',
  search: 'Procurar',
  mandatory_field: 'Este campo é obrigatório.',
  name_in_use: 'Este nome já está a ser usado.',
  crop: 'Cortar',
  published: 'Publicado',
  not_published: 'Não publicados',
  all: 'Todos',
  empty_list: 'Sem dados para mostrar',
  user_chat_failed: 'Este utilizador ainda não está online',
  activity_center: 'Centro de atividade',
  no_recent_activity: 'Sem atividade recente',
  unread_messages: 'Mensagens por ler',
  reply: 'Responder',
  session_invitations: 'Convites por responder',
  accept: 'Aceitar',
  refuse: 'Recusar',
  in_progress: 'Em progresso',
  recent_files: 'Ficheiros recentes',
  open_conversations: 'Conversas em aberto',
  all_fields_required: 'Todos os campos são necessários.',
  something_went_wrong: 'Aconteceu algo de errado',
  already_exists: 'Já existe',
  profile: 'A minha conta',
  filter_by_status: 'Filtrar por estado',
  open_meeting: 'Entrar na reunião',
  visitors: 'Visitantes',
  saved: 'Guardado',
  back: 'Voltar',
  session_full: 'Cheia',
  file_not_image: 'Este formato não é suportado',
  new_export: 'Novo exporte',
  state: 'Estado',
  details: 'Detalhes',
  see_more: 'Ver mais',
  more: 'Mais',
  manage: 'Gerir',
  manage_event: 'Gerir evento',
  see_as_visitor: 'Ver como visitante',
  see_as_exhibitor: 'Ver como expositor',
  manage_visitors: 'Gerir visitantes',
  manage_exhibitors: 'Gerir expositores',
  registered: 'registado',
  refused: 'recusado',
  pending: 'pendente',
  session_manager: 'Gestor de sessão',
  export_questions: 'Exportar perguntas',
  my_mod_questions: 'Últimas perguntas',
  mod_questions: 'Perguntas para moderação',
  pending_plural: 'Pendentes',
  read: 'Lidas',
  mark_as_read: 'Marcar como lida',
  accepted: 'aceite',
  favorites: 'Favoritos',
  add_to_favorites: 'Adicionar aos favoritos',
  remove_from_favorites: 'Remover dos favoritos',
  contact_request: 'Pedido de contacto',
  invited: 'Convidado/a',
  note: 'Nota',
  discard: 'Descartar',
  no_comment: 'Sem comentário',
  click_here: 'Clique aqui',
  sessions: 'Sessões',
  event_sessions: 'Sessões de eventos',
  exhibitor_sessions: 'Sessões de expositores',
  networking: 'Rede',
  total: 'Total',
  update_success: 'Informação atualizada com sucesso',
  agree_to_terms:
    'Aceito os <a href="https://beamian.com/terms/" target="_blank"><b>termos e condições da beamian</b></a>',
  add_to_calendar: 'Adicione ao seu calendário',
  event_already_started: 'Este evento já começou',
  countdown: 'Contagem decrescente',
  please_check_answers: 'Por favor, verifique as suas respostas',
  sort_by: 'Ordenar por',
  order: 'Ordem',
  date: 'Data',
  ascending: 'Ascendente',
  descending: 'Descendente',
  no_data_title: 'Nada para ver aqui!',
  no_data_subtitle: 'Por favor, volte mais tarde.',
  exhibitor_interactions: 'Interações com o expositor',
  interactions: 'Interações',
  product_interactions: 'Interações com ofertas',
  sessions_subtitle: 'Últimos dados sobre sessões e estatísticas',
  online_registrations: 'Inscrições para acesso online',
  in_person_registrations: 'Inscrições para acesso presencial',
  contacts: 'Contactos',
  created: 'Criados',
  seeing_as_visitor: 'A ver evento como visitante',
  total_sessions: 'Total de sessões',
  ticket_types: 'Tipos de bilhete',
  image: 'Imagem',
  add: 'Adicionar',
  tickets: 'Bilhetes',
  sold: 'Vendido',
  price: 'Preço',
  select_exhibitor: 'Seleccionar expositor',
  code: 'Código',
  type: 'Tipo',
  discount: 'Desconto',
  status: 'Visibilidade',
  from: 'De',
  to: 'Para',
  filter: 'Filtro',
  option: 'Opção',
  session_privacy: 'Session privacy',
  private: 'Private',
  public: 'Public',
  my_addons: 'My add-ons',
  see_addons_bought: 'See add-ons/extras you bought',
  form: 'Formulario',
  checkout: 'Checkout',
  product_status: 'Status',
  price_includes_tax: 'Price includes tax',
  filter_visitor_contacts: 'Filtrar os contactos dos visitantes',
  filter_networking_contacts: 'Filtrar contactos para networking',
  filter_exhibitors: 'Filtrar expositores',
  check_sources: 'Filtrar por origem de interacção e respostas dos visitantes',
  filter_visitor_multiple_select:
    'Ao selecionar vários itens, os contactos serão filtrados por todas as opções seleccionadas',
  content_not_allowed: 'You are not allowed to access this content.',
  fill_payment_information: 'Fatura com contribuinte',
  only_letters_numbers_hyphen_underscore:
    'Somente letras, números, hífen e underscore são permitidos',
  registration_closed: 'O registo para :event_name encontra-se encerrado.',
  return_home: 'Voltar à pagina inicial',
  character_limit_1: 'limitado a',
  character_limit_2: 'caracteres',
  booklet_sample: 'Exemplar de livro de visitas',
  invite_managers: 'Convidar/remover gestores',
  scan_for_interactions: 'Ler interações',
  invite_managers_cta: 'Convidar gestores',
  grant_permissions: 'Permissões de gestão',
  grant_permissions_error: 'Erro ao alterar permissões',
  invitation_send_error: 'Erro ao enviar convite',
  permissions_changed: 'Permissão alterada com sucesso',
  pre_registered: 'Em pré-registo',
  evaluate_session: 'Avaliar sessão',
  session_evaluation_title: 'Avaliação da Sessão',
  confirm_vote: 'Deseja confirmar a sua votação?',
  vote_already_submitted: 'A sua resposta a esta pergunta já foi submetida',
  vote_cannot_be_update: 'Sua votação não poderá ser atualizada após a submissão.',
  evaluate_event: 'Avaliar evento',
  event_evaluation_title: 'Avaliação do Evento',
  sold_out: 'Esgotado',
  exhibitors_1347: 'Oradores',
  clear_selection: 'Limpar seleção',
  ticket_label: 'Etiqueta de bilhete',
  ticket_purchase_info: 'Info compra de bilhete',
};

export default common;
